import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { images } from 'assets'
import { colors, stylesHelpers } from 'lib/styles'
import { Typography, Input, Checkbox, Button } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { ModalInformation } from './ModalInformation'

type IsVisible = {
    isVisible: boolean
}

interface HomeScreenModalProps extends IsVisible {
    onClose: VoidFunction
}

type ResponseMessage = {
    error: boolean,
    message?: string
}

type MessageStyles = {
    error: boolean
}

export const HomeScreenModal: React.FunctionComponent<HomeScreenModalProps> = ({ isVisible, onClose }) => {
    const T = useTranslations()
    const [ email, setEmail ] = useState<string>('')
    const [ phone, setPhone ] = useState<string>('')
    const [ isSelected, setIsSelected ] = useState(false)
    const [ response, setResponse ] = useState<ResponseMessage>({
        error: false
    })

    const onMail = useCallback(() => {
        if (!isSelected || !email || !phone) {
            return setResponse(prevState => ({
                ...prevState,
                error: true,
                message: 'Proszę wypełnić wszystkie pola'
            }))
        }

        const xhr = new XMLHttpRequest()
        const body = new FormData()

        body.append('email', email)
        body.append('phone', phone)
        body.append('isSelected', String(isSelected))
        xhr.open('POST', 'mail.php')

        xhr.onload = () => {
            if (xhr.response === '') {
                return null
            }
            const response = JSON.parse(xhr.response)

            return setResponse(prevState => ({
                ...prevState,
                message: response.message,
                error: response.status === 200
                    ? false
                    : response.status === 400
            }))
        }
        xhr.send(body)
    }, [email, phone, isSelected])

    return (
        <Container isVisible={isVisible}>
            <Box>
                <BoxHeader>
                    <CloseButton onClick={() => onClose()}>
                        <CloseIcon src={images.closeIcon}/>
                    </CloseButton>
                </BoxHeader>
                <BoxBody>
                    <Heading>
                        {T.components.modal.title}
                    </Heading>
                    <Subtitle>
                        {T.components.modal.subtitle}
                    </Subtitle>
                    <Input
                        type={'email'}
                        label={T.components.modal.email.label}
                        placeholder={T.components.modal.email.placeholder}
                        value={email}
                        onChange={setEmail}
                    />
                    <Input
                        type={'phone'}
                        label={T.components.modal.phone.label}
                        placeholder={T.components.modal.phone.placeholder}
                        value={phone}
                        onChange={setPhone}
                    />
                    <ModalInformation/>
                    <Checkbox
                        isSelected={isSelected}
                        onChange={setIsSelected}
                        rightComponent={() => (
                            <CheckboxText>
                                {T.components.modal.checkbox.agreement}
                            </CheckboxText>
                        )}
                    />
                    <ButtonWrapper>
                        <Button
                            type="filled"
                            onPress={onMail}
                            text={T.components.modal.send}
                        />
                        {response.message && (
                            <Message error={response.error}>
                                {response.message}
                            </Message>
                        )}
                    </ButtonWrapper>
                </BoxBody>
            </Box>
        </Container>
    )
}
const Container = styled.div<IsVisible>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: ${stylesHelpers.hexToRGBA(colors.mediumBlue, 0.5)};
  display: ${props => props.isVisible ? 'flex' : 'none'};
  padding: 20px;
`

const Box = styled.div`
  max-width: 900px;
  width: 100%;
  background-color: ${colors.white};
  min-height: 400px;
  border-radius: 6px;
  max-height: 700px;
  height: 100%;
  margin: auto;
  overflow: auto;
`

const BoxHeader = styled.div`
  width: 100%;
  height: 40px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${colors.green};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px
`

const CloseButton = styled.div`
  width: 60px;
  height: 40px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`

const CloseIcon = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
`

const BoxBody = styled.div`
  padding: 30px 40px 10px 40px
`

const Heading = styled(Typography.H3)`
  text-align: center;
  margin-bottom: 10px;
  font-size: 26px;
`

const Subtitle = styled(Typography.Paragraph)`
  color: ${colors.gray};
  text-align: center;
  font-weight: 400;
`

const CheckboxText = styled(Typography.Paragraph)`
  color: ${colors.gray};
  font-weight: 400;
  font-size: 14px;
`

const ButtonWrapper = styled.div`
    width: 300px;
    margin: 20px auto;
`

const Message = styled(Typography.Paragraph)<MessageStyles>`
  color: ${props => props.error ? colors.red : colors.green};
  text-align: center;
  margin-top: 10px;
`
