import React from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'

type InputProps = {
    placeholder: string,
    value: string,
    type: string,
    label: string,
    onChange(text: string): void
}

export const Input: React.FunctionComponent<InputProps> = ({
    label,
    value,
    onChange,
    type,
    placeholder
}) => (
    <Container>
        <Label>
            {label}
        </Label>
        <StyleInput
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={({ target }) => onChange(target.value)}
        />
    </Container>
)

const Container = styled.div`
    width: 100%;
    margin-bottom: 20px
`

const Label = styled.div`
    display: block;
    font-weight: 400;
    color: ${colors.black};
    font-size: 14px;
    margin-bottom: 10px;
`

const StyleInput = styled.input`
    width: 100%;
    height: 52px;
    border-radius: 10px;
    color: ${colors.gray};
    font-weight: 400;
    border: solid 2px ${colors.mediumBlue};
    outline: none;
    padding-left: 15px;
    padding-right: 15px;
`
