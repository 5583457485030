import React from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { Section, FluidContainer, Typography } from 'lib/components'
import { INSURANCE_SECTION_ID } from '../constants'
import { InsuranceTabs } from '../components'

type InsuranceSectionProps = {
    toggleModal: VoidFunction
}

export const InsuranceSection: React.FunctionComponent<InsuranceSectionProps> = ({ toggleModal }) => {
    const T = useTranslations()

    return (
        <Container id={INSURANCE_SECTION_ID}>
            <FluidContainer>
                <Header>
                    {T.screens.homeScreen.insuranceSection.title}
                </Header>
                <Subtitle>
                    {T.screens.homeScreen.insuranceSection.subtitle}
                </Subtitle>
                <InsuranceTabs toggleHomeModal={toggleModal}/>
            </FluidContainer>
        </Container>
    )
}

const Container = styled(Section)`
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: ${colors.lightBlue};
`

const Header = styled(Typography.H3)`
    text-align: center;
    margin-bottom: 10px;
`

const Subtitle = styled(Typography.Paragraph)`
    color: ${colors.gray};
    text-align: center;
    font-weight: 400;
`
