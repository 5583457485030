import React, { useEffect, } from 'react'
import styled from 'styled-components'
import { useCountUp } from 'react-countup'
import { images } from 'assets'
import { colors, MediaBreakpoints } from 'lib/styles'
import { Button } from 'lib/components'
import { useTranslations, useHover } from 'lib/hooks'
import { Tabs } from '../types'
import { ReadMoreHover } from './ReadMoreHover'
import { AppLinks } from '../../../lib/common';

type IsMainPromotion = {
    isMainPromotion?: boolean
}

interface InsuranceElementProps extends IsMainPromotion {
    title: string,
    icon: string,
    amount: number,
    newAmount: number,
    activeTab: Tabs,
    onContactButtonClick: VoidFunction,
    renderContent(): React.ReactNode,
    renderHoverContent(): React.ReactNode
}

export const InsuranceElement: React.FunctionComponent<InsuranceElementProps> = ({
    isMainPromotion,
    icon,
    title,
    renderContent,
    amount,
    newAmount,
    activeTab,
    onContactButtonClick,
    renderHoverContent
}) => {
    const T = useTranslations()
    const { ref, isHovered, setIsHovered, } = useHover<HTMLDivElement>()
    const { countUp, start, reset } = useCountUp({
        start: amount,
        end: newAmount,
        suffix: ` ${T.common.pln}`,
        startOnMount: false,
        useEasing: true,
        duration: 3
    })

    useEffect(() => {
        if (activeTab === Tabs.Second) {
            start()
        }

        if (activeTab === Tabs.First) {
            reset()
        }
    }, [activeTab])

    return (
        <Container isMainPromotion={isMainPromotion}>
            {isMainPromotion ? (
                <MainPromotionHeader>
                    {T.screens.homeScreen.insuranceSection.tabs.bestSellerTitle}
                </MainPromotionHeader>
            ) : (
                <PromotionFakeHeader/>
            )}
            <PromotionBody isMainPromotion={isMainPromotion}>
                <PromotionHeader>
                    <PromotionImage src={icon}/>
                    <PromotionTitle>
                        {title}
                    </PromotionTitle>
                </PromotionHeader>
                <PromotionContent>
                    {renderContent()}
                </PromotionContent>
                <ReadMore ref={ref}>
                    {T.common.readMore}
                    <ReadMoreIcon src={images.arrowRight}/>
                    <ReadMoreHover
                        isVisible={isHovered}
                        closeHover={() => setIsHovered(false)}
                        renderContent={renderHoverContent}
                    />
                </ReadMore>
                <PromoPriceContainer>
                    <PriceTitle>
                        {T.screens.homeScreen.insuranceSection.tabs.collection}
                    </PriceTitle>
                    <Price>
                        {countUp}
                    </Price>
                </PromoPriceContainer>
                <ButtonContainer>
                    <ButtonWrapper>
                        <Button
                            type="filled"
                            text={T.common.buy}
                            onPress={() => window.open(AppLinks.BuyNow)}
                        />
                    </ButtonWrapper>
                    <Button
                        type="outline"
                        text={T.common.contact}
                        onPress={() => onContactButtonClick()}
                    />

                </ButtonContainer>
            </PromotionBody>
        </Container>
    )
}

const Container = styled.div<IsMainPromotion>`
    flex: 1;
    margin: 0px 10px;
    @media (max-width: ${MediaBreakpoints.MD}px) {
        border-top: ${props => props.isMainPromotion ? `solid 4px ${colors.lightBlue}` : undefined};
        border-bottom: ${props => props.isMainPromotion ? `solid 4px ${colors.lightBlue}` : undefined};
        margin: 20px;
        padding: 40px 0px;
    }
`

const MainPromotionHeader = styled.div`
    height: 35px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-transform: uppercase;
    font-size: 14px;
    color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.red};
    margin-bottom: 10px;
`

const PromotionFakeHeader = styled.div`
    height: 35px;
    margin-bottom: 10px;
    @media (max-width: ${MediaBreakpoints.LG}px) {
        display: none;
    }
`

const PromotionBody = styled.div<IsMainPromotion>`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    border-left: ${props => props.isMainPromotion ? `solid 4px ${colors.lightBlue}` : undefined};
    border-right: ${props => props.isMainPromotion ? `solid 4px ${colors.lightBlue}` : undefined};
    @media (max-width: ${MediaBreakpoints.MD}px) {
        border-left: none;
        border-right: none;
    }
`

const PromotionHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
`

const PromotionImage = styled.img`
    object-fit: contain;
`

const PromotionTitle = styled.div`
    font-size: 12px;
    color: ${colors.mediumBlue};
    font-weight: bold;
    margin-top: 10px;
`

const PromotionContent = styled.div`
    width: 100%;
    margin-top: 25px;
`

const PromoPriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
`

const PriceTitle = styled.div`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
`

const Price = styled.div`
    color: ${colors.green};
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
`

const ButtonContainer = styled.div`
    margin-top: 20px;
    width: 100%;
`

const ButtonWrapper = styled.div`
    display: block;
    margin-bottom: 10px;
`

const ReadMore = styled.div`
    position: relative;
    color: ${colors.gray};
    font-size: 10px;
    cursor: pointer;
    user-select: none;
    display: flex;
    margin-bottom: 20px;
    max-width: 150px;
    @media (max-width: ${MediaBreakpoints.XXL}px) {
        max-width: 100%;
        width: 100%;
    }
`

const ReadMoreIcon = styled.img`
    object-fit: contain;
    margin-left: 3px;
`
