import React from 'react'
import styled from 'styled-components'
import { useTranslations } from 'lib/hooks'
import { Text, BlueSpan } from './Text'

export const OtherInformationCollapsable3: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.basicInformationSection.otherInformations

    return (
        <Text>
            <BlueSpan>
                {T.collapsable3.body.blue1}
            </BlueSpan>
            {T.collapsable3.body.black1}
            <BlueSpan>
                {T.collapsable3.body.blue2}
            </BlueSpan>
            {T.collapsable3.body.black2}
            <BlueSpan>
                {T.collapsable3.body.blue3}
            </BlueSpan>
            {T.collapsable3.body.black3}
            <BlueSpan>
                {T.collapsable3.body.blue4}
            </BlueSpan>
            {T.collapsable3.body.black4}
            <BlueSpan>
                {T.collapsable3.body.blue5}
            </BlueSpan>
            {T.collapsable3.body.black5}
            <BlueSpan>
                {T.collapsable3.body.blue6}
            </BlueSpan>
            {T.collapsable3.body.black6}
            <BlueSpan>
                {T.collapsable3.body.blue7}
            </BlueSpan>
            {T.collapsable3.body.black7}
            <BlueSpan>
                {T.collapsable3.body.blue8}
            </BlueSpan>
            {T.collapsable3.body.black8}
            <BlueSpan>
                {T.collapsable3.body.blue9}
            </BlueSpan>
            {T.collapsable3.body.black9}
            <BlueSpan>
                {T.collapsable3.body.blue10}
            </BlueSpan>
            {T.collapsable3.body.black10}
            <BlueSpan>
                {T.collapsable3.body.blue11}
            </BlueSpan>
            {T.collapsable3.body.black11}
            <BlueSpan>
                {T.collapsable3.body.blue12}
            </BlueSpan>
            {T.collapsable3.body.black12}
            <BlueSpan>
                {T.collapsable3.body.blue13}
            </BlueSpan>
        </Text>
    )
}

const Image = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 2px;
    object-fit: contain;
    padding-top: 5px;
`

