import React from 'react'
import styled from 'styled-components'
import { images } from 'assets'
import { colors } from 'lib/styles'
import { Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'

export const FooterContact: React.FunctionComponent = () => {
    const T = useTranslations()

    return (
        <Container>
            <ContainerHeader/>
            <Body>
                <HeaderText>
                    {T.screens.homeScreen.footerSection.contactBox.title}
                </HeaderText>
                <ContactRow>
                    <RowIcon src={images.phoneIcon}/>
                    <RowBody>
                        <GreenText>
                            {T.screens.homeScreen.footerSection.contactBox.phone}
                        </GreenText>
                        <GrayText>
                            {T.screens.homeScreen.footerSection.contactBox.openHours}
                        </GrayText>
                    </RowBody>
                </ContactRow>
                <ContactRow>
                    <RowIcon src={images.emailIcon}/>
                    <RowBody>
                        <GreenText>
                            {T.common.email}
                        </GreenText>
                    </RowBody>
                </ContactRow>
            </Body>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    min-height: 300px;
    background-color: ${colors.white};
    border-radius: 6px;
    overflow: hidden;
    margin-top: -100px;
    box-shadow: ${colors.boxShadow};
`

const ContainerHeader = styled.div`
    width: 100%;
    height: 12px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: ${colors.green};
`

const Body = styled.div`
    padding: 40px
`

const HeaderText = styled(Typography.Paragraph)`
    color: ${colors.blue};
    font-weight: 600;
    white-space: pre-line;
    margin-bottom: 30px
`

const ContactRow = styled.div`
    display: flex;
    margin-bottom: 25px;
`

const RowIcon = styled.img`
    margin-right: 10px;
    width: 25px;
    height: 25px;
    object-fit: contain;
`

const RowBody = styled.div`
    display: flex;
    flex-direction: column;
`

const GreenText = styled(Typography.Paragraph)`
    color: ${colors.green};
    font-weight: 400;
    word-break: break-all;
`

const GrayText = styled(Typography.Paragraph)`
    color: ${colors.gray};
    font-weight: 400;
`
