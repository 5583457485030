import React from 'react'
import styled from 'styled-components'
import { images } from 'assets'
import { colors, MediaBreakpoints } from 'lib/styles'

type IsVisible = {
    isVisible: boolean
}

interface ReadMoreHoverProps extends IsVisible {
    closeHover: VoidFunction,
    renderContent(): React.ReactNode
}

export const ReadMoreHover: React.FunctionComponent<ReadMoreHoverProps> = ({ renderContent, isVisible, closeHover }) => (
    <Container isVisible={isVisible}>
        <CloseButton onClick={closeHover}>
            <CloseIcon src={images.closeIcon}/>
        </CloseButton>
        {renderContent()}
    </Container>
)

const Container = styled.div<IsVisible>`
    width: 300px;
    border-radius: 6px;
    background-color: ${colors.mediumBlue};
    display: ${props => props.isVisible ? 'block' : 'none'};
    position: absolute;
    top: -10px;
    left: 160px;
    z-index: 999;
    &::before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 8px 8px 0;
        border-color: transparent ${colors.mediumBlue} transparent transparent;
        position: absolute;
        left: -8px;
        top: 10px;
        @media (max-width: ${MediaBreakpoints.XXL}px) {
            top: -8px;
            left: 10px;
            border-width: 0 8px 8px 8px;
            border-color: transparent transparent ${colors.mediumBlue} transparent;
        }
        @media (max-width: ${MediaBreakpoints.SM}px) {
            left: 55px;
        }
    }
    @media (max-width: ${MediaBreakpoints.XXL}px) {
        top: 30px;
        left: 0;
        right: 0;
    }
    @media (max-width: ${MediaBreakpoints.SM}px) {
        top: 30px;
        left: -48px;
        width: calc(100vw - 30px);
    }
`

const CloseButton = styled.div`
    display: none;
    @media (max-width: ${MediaBreakpoints.LG}px) {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px
    }
`

const CloseIcon = styled.img`
    object-fit: contain;
`
