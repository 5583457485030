import { useEffect, useRef, useState } from 'react'

export const useHover = <T extends HTMLElement>() => {
    const [ isHovered, setIsHovered ] = useState(false)
    const ref = useRef<T>(null)

    useEffect(() => {
        const handleMouseLeave = () => setIsHovered(false)
        const handleMouseEnter = () => setIsHovered(true)
        const node = ref.current

        if (node) {
            node.addEventListener('mouseenter', handleMouseEnter)
            node.addEventListener('mouseleave', handleMouseLeave)

            return () => {
                node.removeEventListener('mouseenter', handleMouseEnter)
                node.removeEventListener('mouseleave', handleMouseLeave)
            }
        }

        return () => {}
    }, [ref, setIsHovered])

    return {
        ref,
        isHovered,
        setIsHovered
    }
}
