import styled from 'styled-components'
import { colors } from 'lib/styles'

type H2Props = {
    color?: string,
    size?: number,
    bold?: boolean
}

export const H2 = styled.h2<H2Props>`
    color: ${props => props.color || colors.white};
    font-size: ${props => props.size || 24}px;
    font-weight: ${props => props.bold ? 'bold' : 'normal'};
    margin: 0;
    text-align: center;
`
