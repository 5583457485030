import React from 'react'
import styled from 'styled-components'
import { Typography, Collapsable } from 'lib/components'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { GoodToKnowCollapsable1, GoodToKnowCollapsable2, GoodToKnowCollapsable3, GoodToKnowCollapsable4, GoodToKnowCollapsable5 } from './collapsables'

export const GoodToKnow: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.basicInformationSection

    return (
        <Container>
            <Subtitle>
                {T.goodToKnow.title}
            </Subtitle>
            <Collapsable title={T.goodToKnow.collapsable1.title}>
                <GoodToKnowCollapsable1/>
            </Collapsable>
            <Collapsable title={T.goodToKnow.collapsable2.title}>
                <GoodToKnowCollapsable2/>
            </Collapsable>
            <Collapsable title={T.goodToKnow.collapsable3.title}>
                <GoodToKnowCollapsable3/>
            </Collapsable>
            <Collapsable title={T.goodToKnow.collapsable4.title}>
                <GoodToKnowCollapsable4/>
            </Collapsable>
            <Collapsable title={T.goodToKnow.collapsable5.title}>
                <GoodToKnowCollapsable5/>
            </Collapsable>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
`

const Subtitle = styled(Typography.Paragraph)`
    font-size: 16px;
    color: ${colors.mediumBlue};
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
    font-weight: bold;
`
