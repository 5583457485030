import React from 'react'
import styled from 'styled-components'
import { Typography, Collapsable } from 'lib/components'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import {
    OtherInformationCollapsable1,
    OtherInformationCollapsable2,
    OtherInformationCollapsable3
} from './collapsables'

export const OtherInformations: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.basicInformationSection

    return (
        <Container>
            <Subtitle>
                {T.otherInformations.title}
            </Subtitle>
            <Collapsable title={T.otherInformations.collapsable1.title}>
                <OtherInformationCollapsable1/>
            </Collapsable>
            <Collapsable title={T.otherInformations.collapsable2.title}>
                <OtherInformationCollapsable2/>
            </Collapsable>
            <Collapsable title={T.otherInformations.collapsable3.title}>
                <OtherInformationCollapsable3/>
            </Collapsable>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    margin-top: 100px;
`

const Subtitle = styled(Typography.Paragraph)`
    font-size: 16px;
    color: ${colors.mediumBlue};
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bold;
`
