import React from 'react'
import styled from 'styled-components'
import { images } from 'assets'
import { AppLinks } from 'lib/common'
import { useTranslations } from 'lib/hooks'
import { Text, BlueSpan, Link } from './Text'

export const OtherInformationCollapsable2: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.basicInformationSection.otherInformations

    return (
        <Text>
            <BlueSpan>
                {T.collapsable2.body.blue1}
            </BlueSpan>
            {T.collapsable2.body.black1}
            <BlueSpan>
                {T.collapsable2.body.blue2}
            </BlueSpan>
            {T.collapsable2.body.black2}
            <BlueSpan>
                {T.collapsable2.body.blue3}
            </BlueSpan>
            {T.collapsable2.body.black3}
            <BlueSpan>
                {T.collapsable2.body.blue4}
            </BlueSpan>
            {T.collapsable2.body.black4}
            <BlueSpan>
                {T.collapsable2.body.blue5}
            </BlueSpan>
            {T.collapsable2.body.black5}
            <BlueSpan>
                {T.collapsable2.body.blue6}
            </BlueSpan>
            {T.collapsable2.body.black6}
            <BlueSpan>
                {T.collapsable2.body.blue7}
            </BlueSpan>
            {T.collapsable2.body.black7}
            <BlueSpan>
                {T.collapsable2.body.blue8}
            </BlueSpan>
            {T.collapsable2.body.black8}
            <Link href={AppLinks.ResignForm}>
                <BlueSpan>
                    <Image src={images.fileIcon}/>
                    {T.collapsable2.body.blue9}
                </BlueSpan>
            </Link>
        </Text>
    )
}

const Image = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 2px;
    object-fit: contain;
    padding-top: 5px;
`

