import React from 'react'
import { useTranslations } from 'lib/hooks'
import { Text, BlueSpan } from './Text'

export const MostCommonCollapsable6: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.basicInformationSection.mostCommonQuestions

    return (
        <Text>
            <BlueSpan>
                {T.collapsable6.body.blue1}
            </BlueSpan>
            {T.collapsable6.body.black1}
            <BlueSpan>
                {T.collapsable6.body.blue2}
            </BlueSpan>
            {T.collapsable6.body.black2}
            <BlueSpan>
                {T.collapsable6.body.blue3}
            </BlueSpan>
            {T.collapsable6.body.black3}
            <BlueSpan>
                {T.collapsable6.body.blue4}
            </BlueSpan>
            {T.collapsable6.body.black4}
            <BlueSpan>
                {T.collapsable6.body.blue5}
            </BlueSpan>
            {T.collapsable6.body.black5}
        </Text>
    )
}
