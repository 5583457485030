import React from 'react'
import styled from 'styled-components'
import { Typography } from 'lib/components'
import { useTranslations } from 'lib/hooks'
import { colors } from 'lib/styles'

export const LongInfoText: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.infoSection.rightColumn

    return (
        <Container>
            <Paragraph>
                {T.longText.black1}
                <Span>
                    {T.longText.blue1}
                </Span>
                {T.longText.black2}
                <Span>
                    {T.longText.blue2}
                </Span>
                {T.longText.black3}
                <Span>
                    {T.longText.blue3}
                </Span>
                {T.longText.black4}
                <Span>
                    {T.longText.blue5}
                </Span>
                {T.longText.black6}
                <Span>
                    {T.longText.blue6}
                </Span>
                {T.longText.black7}
                <Span>
                    {T.longText.blue7}
                </Span>
                    {T.longText.black8}
                <Span>
                    {T.longText.blue8}
                </Span>
            </Paragraph>
            <InfoCloudWrapper>
                <InfoCloudNumber>1</InfoCloudNumber>
                <InfoCloud>
                    <InfoCloudText>
                        {T.steps.first}
                    </InfoCloudText>
                </InfoCloud>
            </InfoCloudWrapper>
            <InfoCloudWrapper>
                <InfoCloudNumber>2</InfoCloudNumber>
                <InfoCloud>
                    <InfoCloudText>
                        {T.steps.second.black1}
                        <Span>
                            {T.steps.second.blue1}
                        </Span>
                        {T.steps.second.black2}
                        <Span>
                            {T.steps.second.blue2}
                        </Span>
                    </InfoCloudText>
                </InfoCloud>
            </InfoCloudWrapper>
            <InfoCloudWrapper>
                <InfoCloudNumber>3</InfoCloudNumber>
                <InfoCloud>
                    <InfoCloudText>
                        {T.steps.third}
                    </InfoCloudText>
                </InfoCloud>
            </InfoCloudWrapper>
            <BottomParagraph>
                {T.bottom.black}
                <Span>
                    {T.bottom.blue}
                </Span>
            </BottomParagraph>
        </Container>
    )
}

const Container = styled.div`
    display: block;
    width: 100%;
`

const Paragraph = styled(Typography.Paragraph)`
    font-weight: 400;
    white-space: pre-line;
    margin-bottom: 20px;
`

const BottomParagraph = styled(Typography.Paragraph)`
    font-weight: 400;
    white-space: pre-line;
    margin-top: 50px;
`

const Span = styled.span`
    color: ${colors.mediumBlue};
    font-weight: bold;
`

const InfoCloudWrapper = styled.div`
    display: flex;
`

const InfoCloudNumber = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 18px;
    background-color: ${colors.mediumBlue};
    color: ${colors.white};
    margin-right: 20px;
    text-align: center;
    font-weight: bold;
    padding-top: 6px;
`

const InfoCloud = styled.div`
    width: calc(100% - 60px);
    border-radius: 6px;
    box-shadow: ${colors.boxShadow};
    background-color: ${colors.lightBlue};
    padding: 20px;
    margin-bottom: 20px;
    word-break: break-word;
`

const InfoCloudText = styled(Typography.Paragraph)`
    font-weight: 400;
    white-space: pre-line;
`
