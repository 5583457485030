import React, { useState } from 'react'
import { images } from 'assets'
import { Layout } from 'lib/components'
import { HomeScreenModal } from '../components'
import {
    StartSection,
    InsuranceSection,
    ParallaxSection,
    FooterSection,
    InfoSection,
    BasicInformationSection
} from '../sections'

export const HomeScreen: React.FunctionComponent = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    return (
        <React.Fragment>
            <Layout isModalVisible={isModalVisible}>
                <StartSection/>
                <InsuranceSection toggleModal={() => setIsModalVisible(true)}/>
                <ParallaxSection imageUrl={images.parallax1}/>
                <InfoSection/>
                <ParallaxSection imageUrl={images.parallax2}/>
                <BasicInformationSection/>
                <FooterSection/>
            </Layout>
            <HomeScreenModal
                isVisible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
            />
        </React.Fragment>
    )
}
