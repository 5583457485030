import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Grid, Col, Row } from 'react-flexbox-grid'
import { images } from 'assets'
import { colors, MediaBreakpoints } from 'lib/styles'
import { Section, FluidContainer, Typography } from 'lib/components'
import { useTranslations, useWindowResize } from 'lib/hooks'
import { platformUtils } from 'lib/utils'
import { AppLinks } from 'lib/common'
import { FooterContact } from '../components'

export const FooterSection: React.FunctionComponent = () => {
    const T = useTranslations()
    const { width } = useWindowResize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setIsMobile(platformUtils.isPhoneResolution(width))
    }, [width])

    return (
        <Container>
            <FluidContainer>
                <Grid fluid>
                    {!isMobile && (
                        <Row>
                            <Col md={4}>
                                <FooterText>
                                    {T.screens.homeScreen.footerSection.contactDetails}
                                </FooterText>
                                <Link href={AppLinks.TermsAndConditions}>
                                    {T.screens.homeScreen.footerSection.terms}
                                </Link>
                            </Col>
                            <Col md={4}>
                                <FooterText>
                                    {T.screens.homeScreen.footerSection.offer}
                                </FooterText>
                                <Logos src={images.footerLogos}/>
                            </Col>
                            <Col md={4}>
                                <FooterContact/>
                            </Col>
                        </Row>
                    )}
                    {isMobile && (
                        <Row>
                            <Col sm={12}>
                                <FooterContact/>
                                <FooterText>
                                    {T.screens.homeScreen.footerSection.contactDetails}
                                </FooterText>
                                <FooterText>
                                    {T.screens.homeScreen.footerSection.offer}
                                </FooterText>
                                <Logos src={images.footerLogos}/>
                                <Link href={AppLinks.TermsAndConditions}>
                                    {T.screens.homeScreen.footerSection.terms}
                                </Link>
                            </Col>
                        </Row>
                    )}
                </Grid>
            </FluidContainer>
        </Container>
    )
}

const Container = styled(Section)`
    background-color: ${colors.blue};
    min-height: 300px;
    padding-top: 50px;
    padding-bottom: 20px;
`

const FooterText = styled(Typography.Paragraph)`
    color: ${colors.white};
    font-weight: 400;
    white-space: pre-line;
    margin-bottom: 30px;
    font-size: 14px;
    @media (max-width: ${MediaBreakpoints.MD}px) {
        margin-top: 40px;
    }
`

const Link = styled.a`
    color: ${colors.white};
    font-weight: 400;
    white-space: pre-line;
    margin-bottom: 50px;
    font-size: 14px;
    text-decoration: underline;
`

const Logos = styled.img`
    max-width: 100%;
    height: auto;
    object-fit: contain;
    display: block;
    margin-bottom: 40px;
`
