import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { images } from 'assets'
import { MediaBreakpoints, colors } from 'lib/styles'
import { useTranslations, useWindowResize } from 'lib/hooks'
import { FluidContainer, Section, Typography } from 'lib/components'
import { platformUtils } from 'lib/utils'
import { INSURANCE_SECTION_ID } from '../constants'

export const StartSection: React.FunctionComponent = () => {
    const T = useTranslations()
    const { width } = useWindowResize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setIsMobile(platformUtils.isPhoneResolution(width))
    }, [width])

    return (
        <Container backgroundImgUrl={images.homeBg}>
            <FluidContainer maxWidth={1650}>
                <Logo src={images.logo}/>
                <Slogan src={isMobile ? images.homeMobileSlogan : images.homeSlogan}/>
                <ArrowContainer>
                    <ArrowText>
                        {T.screens.homeScreen.startSection.selectInsurance}
                    </ArrowText>
                    <ArrowLink
                        onClick={() => {
                            const element = document.getElementById(INSURANCE_SECTION_ID) as HTMLDivElement

                            element.scrollIntoView({
                                behavior: 'smooth'
                            })
                        }}
                    >
                        <Arrow src={images.arrowDown}/>
                    </ArrowLink>
                </ArrowContainer>
            </FluidContainer>
        </Container>
    )
}

const Container = styled(Section)`
    height: 100vh;
    min-height: 750px;
`

const Logo = styled.img`
    display: block;
`

const Slogan = styled.img`
    margin-top: 100px;
    width: 64%;
    @media (max-width: ${MediaBreakpoints.LG}px) {
        width: 90%;
    }
    @media (max-width: ${MediaBreakpoints.MD}px) {
        margin-top: 50px;
        width: 100%;
    }
`

const ArrowContainer = styled.div`
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: ${MediaBreakpoints.MD}px) {
        bottom: 20px;
    }
`

const ArrowText = styled(Typography.Paragraph)`
    color: ${colors.white};
    font-weight: bold;
    margin-bottom: 10px;
`

const ArrowLink = styled.div``

const Arrow = styled.img`
    margin: 0 auto;
    cursor: pointer;
    width: 64px;
    height: 64px;
    object-fit: contain;
    @media (max-width: ${MediaBreakpoints.MD}px) {
        width: 40px;
        height: 40px;
    }
`
