import React from 'react'
import styled from 'styled-components'
import { MediaBreakpoints } from 'lib/styles'

type ParallaxSectionProps = {
    imageUrl: string
}

export const ParallaxSection: React.FunctionComponent<ParallaxSectionProps> = ({ imageUrl }) => (
    <Container imageUrl={imageUrl}>
        <FakeInnerContent />
    </Container>
)

const Container = styled.div<ParallaxSectionProps>`
    background-image: url("${props => props.imageUrl}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    height: 390px;
    @media (max-width: ${MediaBreakpoints.SM}px) {
        height: 225px;
    }
`

const FakeInnerContent = styled.div`
    width: 100%;
    height: 100%;
`
