import React from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { FluidContainer, Section, Typography } from 'lib/components'
import { GoodToKnow, MostCommonQuestions, OtherInformations } from '../components'

export const BasicInformationSection: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.basicInformationSection

    return (
        <Container backgroundColor={colors.lightBlue}>
            <FluidContainer>
                <Header>
                    {T.title}
                </Header>
                <GoodToKnow/>
                <MostCommonQuestions/>
                <OtherInformations/>
            </FluidContainer>
        </Container>
    )
}

const Container = styled(Section)`
    padding-top: 50px;
    padding-bottom: 100px;
`

const Header = styled(Typography.H3)`
    text-align: center;
    margin-bottom: 10px;
`
