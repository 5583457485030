import styled from 'styled-components'
import { colors } from '../styles'

type SectionProps = {
    backgroundImgUrl?: string,
    backgroundColor?: string
}

export const Section = styled.div<SectionProps>`
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: ${props => props.backgroundColor || colors.white};
    background-image: url('${props => props.backgroundImgUrl || 'unset'}');
`
