import { Dictionary } from '../types'

export const pl_PL: Dictionary = {
    common: {
        ok: 'Ok',
        email: 'fitcasco@bikserwis.pl',
        pln: 'zł',
        buy: 'Kupuję',
        contact: 'Proszę o kontakt',
        readMore: 'Przeczytaj szczegółowy opis'
    },
    components: {
        modal: {
            title: 'Zostaw swoje dane',
            subtitle: 'Oddzwonimy!',
            email: {
                label: 'adres-email',
                placeholder: 'przykład@fitcasco.pl'
            },
            phone: {
                label: 'numer telefonu',
                placeholder: '+48 000 000 000'
            },
            checkbox: {
                firstParagraph: 'W celu przedstawienia oferty w zakresie ubezpieczenia fitcasco, zapytamy tylko i wyłącznie o dane niezbędne do sporządzenia kalkulacji. Przed wprowadzeniem danych do prosimy o zapoznanie się z treścią klauzuli informacyjnej fitcasco.',
                secondParagraph: {
                    gray: 'Prosimy o potwierdzenie zapoznania się z ',
                    blue: 'treścią klauzuli informacyjnej fitcasco'
                },
                thirdParagraph: 'Klauzula określa zasady przetwarzania Twoich danych osobowych przez Administratora Danych Osobowych, zawiera informacje o Dystrybutorze Ubezpieczeń, zapisy Regulaminu Serwisu i Politykę Prywatności. Przejście dalej oznacza wyrażenie woli skorzystania z usług zgodnie z Regulaminem Serwisu.',
                agreement: 'Potwierdzam zapoznanie się z klauzulą informacyjną fitcasco.'
            },
            send: 'wyślij'
        }
    },
    screens: {
        notFoundScreen: {
            title: '404',
            subtitle: 'Strona o podanym adresie nie istnieje'
        },
        homeScreen: {
            startSection: {
                selectInsurance: 'Wybierz swoją składkę'
            },
            insuranceSection: {
                title: 'Zobacz składkę',
                subtitle: 'Wysokość składki jest zależna od Twojej obecnej historii AC. Zaznacz swoją opcję i wybierz spośród 3 wariantów.',
                tabs: {
                    leftTabTitle: 'W ciągu ostatnich 3 lat miałeś maksymalnie\n',
                    leftTabBoldTitle: '1 szkodę',
                    rightTabTitle: 'W ciągu ostatnich 3 lat miałeś  maksymalnie\n',
                    rightTabBoldTitle: '2 szkody',
                    bestSellerTitle: 'Bestseller',
                    collection: 'Składka',
                    semiFit: {
                        title: 'Semi FIT 5 000',
                        body: {
                            black1: 'Jeśli Twoje auto zostanie skradzione lub po kolizji nie będzie się już nadawało do naprawy, ',
                            blue1: 'pokryjemy od 100% szkody.\n\n',
                            black2: 'Jeśli Twoje auto będzie uszkodzone częściowo, pokryjemy ',
                            blue2: 'wszystkie naprawy do kwoty 5 000 zł.'
                        },
                        hover: {
                            title: 'wariant 5 000 zł',
                            paragraph: 'W ramach niniejszego wariantu, zawarte ubezpieczenie obejmuje odpowiedzialność odszkodowawczą z tytułu:',
                            adventages: [
                                'szkody częściowej – do maksymalnej kwoty 5 000 zł/10 000 zł na jedno i wszystkie zdarzenia w okresie ubezpieczenia',
                                'szkody kradzieżowej - do wartości, pojazdu, obliczonej na dzień kradzieży pojazdu (równej początkowej sumie ubezpieczenia podanej w ubezpieczeniu, pomniejszonej o rzeczywiste użytkowanie do dnia zdarzenia objętego ubezpieczeniem)',
                                'szkody całkowitej - obliczane jak w przypadku szkody kradzieżowej'
                            ]
                        }
                    },
                    basicFit: {
                        title: 'Basic FIT',
                        body: {
                            red1: 'Jeśli Twoje auto zostanie skradzione ',
                            black1: 'lub po kolizji nie będzie się już nadawało do naprawy,\n',
                            red2: 'pokryjemy do 100% szkody.'
                        },
                        hover: {
                            title: 'wariant szkoda całkowita',
                            paragraph: 'W ramach niniejszego wariantu, zawarte ubezpieczenie obejmuje odpowiedzialność odszkodowawczą z tytułu:',
                            adventages: [
                                'szkody kradzieżowej – do wartości, pojazdu z dnia szkody (nie większej niż suma ubezpieczenia na polisie)',
                                'szkody całkowitej - obliczane jak w przypadku szkody kradzieżowej'
                            ]
                        }
                    },
                    fullFit: {
                        title: 'Full FIT 10 000',
                        body: {
                            black1: 'Jeśli Twoje auto zostanie skradzione lub po kolizji nie będzie się już nadawało do naprawy, ',
                            blue1: 'pokryjemy od 100% szkody.\n\n',
                            black2: 'Jeśli Twoje auto będzie uszkodzone częściowo, pokryjemy ',
                            blue2: 'wszystkie naprawy do kwoty 10 000 zł.'
                        },
                        hover: {
                            title: 'wariant 10 000 zł',
                            paragraph: 'W ramach niniejszego wariantu, zawarte ubezpieczenie obejmuje odpowiedzialność odszkodowawczą z tytułu:',
                            adventages: [
                                'szkód częściowych - do maksymalnej kwoty 10000 zł w ramach jednego okresu ubezpieczenia',
                                'szkody kradzieżowej - do wartości pojazdu, obliczonej na dzień kradzieży pojazdu (równej początkowej sumie ubezpieczenia podanej w ubezpieczeniu, pomniejszonej o rzeczywiste użytkowanie do dnia zdarzenia objętego ubezpieczeniem)',
                                'szkody całkowitej – w przypadku, gdy wysokość szkody przekracza 70% wartości pojazdu z dnia szkody'
                            ]
                        }
                    }
                }
            },
            infoSection: {
                leftColumn: {
                    title: 'Jak to możliwe, że składki są tak niskie?',
                    information: [
                        'Możemy pozwolić sobie na tak niskie składki, ponieważ proponujemy rozwiązania, które zapewniają ochronę z AC wyłącznie w następstwie najczęściej zgłaszanych szkód. Nie musisz płacić wysokiej ceny za\u00A0ubezpieczenie auta od burzy piaskowej, która nigdy się nie zdarzyła w tej części świata. My podchodzimy do tego praktycznie, ponieważ myślimy o: zarysowaniach na parkingu supermarketu, nocnych obcierkach pod blokiem czy miejskich stłuczkach.',
                        'Dodatkowo porównujemy oferty trzech powszechnie znanych Towarzystw Ubezpieczeniowych, z których kalkulator fitcasco wybierze najkorzystniejsze dla Ciebie rozwiązanie. Wszystko mamy sprawdzone, dlatego łatwiej nam znaleźć i zaproponować Ci coś dokładnie na Twoją miarę.',
                        'Dziś jazda samochodem to codzienność, więc zdarzają nam się codzienne sytuacje. Dlatego płać tylko za to, co naprawdę może Ci się przydarzyć!'

                    ]
                },
                rightColumn: {
                    title: 'Likwidacja szkody?\nWygodna i bezproblemowa!',
                    longText: {
                        black1: 'Zastanawiasz się, ',
                        blue1: 'co się stanie, ',
                        black2: 'gdy spotka Cię samochodowy pech? Zgłosisz szkodę i co potem? ',
                        blue2: 'Nie zawracaj sobie tym głowy, bo jesteśmy przygotowani na każdą sytuację. ',
                        black3: 'My proponujemy – Ty wybierasz najlepsze dla Ciebie rozwiązanie!\n\nZawsze oferujemy ',
                        blue3: '3 sposoby likwidacji szkody ',
                        black4: '– jeden kosztorysowy i dwa bezgotówkowe. Na czym to polega? Jeśli zdecydujesz się na wariant pierwszy, po prostu ',
                        blue4: 'otrzymasz pieniądze na naprawę auta ',
                        black5: 'i to wszystko. Jeśli jednak uznasz, że interesuje Cię rozwiązanie bezgotówkowe, zaproponujemy Ci opcję Autoryzowanej Stacji Obsługi, czyli ',
                        blue5: 'naprawę w autoryzowanym serwisie lub w jednym z warsztatów partnerskich BIK. ',
                        black6: 'Sieć jest dostępna w całym kraju, więc łatwo znajdziesz najbliższy punkt.\n\nPo dokonaniu naprawy przez ASO lub w jednym z warsztatów sieci partnerskiej BIK, ',
                        blue6: 'odbierzesz gotowy do jazdy samochód! ',
                        black7: 'Widzisz, to naprawdę proste i przejrzyste rozwiązanie!\n\n',
                        blue7: 'Zgłoszenie szkody też jest banalnie proste i bardzo wygodne. ',
                        black8: 'Ważne tylko, byś poinformował nas niezwłocznie po zajściu lub po uzyskaniu informacji o szkodzie.\n\nA teraz zapoznaj się „na sucho” z procesem zgłoszenia, byś w razie konieczności nie stresował się dodatkowo.\n\n',
                        blue8: 'Przejedź to z nami krok po kroku:'
                    },
                    steps: {
                        first: 'Przed zgłoszeniem szkody przygotuj numer polisy lub numer rejestracyjny samochodu.',
                        second: {
                            black1: 'Zgłoś szkodę:\n\ndzwoniąc na całodobową infolinię - ',
                            blue1: '+58 55 49 949\n',
                            black2: 'lub\npisząc mail - ',
                            blue2: 'likwidacjaszkod@bikserwis.pl'
                        },
                        third: 'Zostaniesz zapytany o okoliczności zdarzenia, jak: data, godzina i zakres uszkodzeń w samochodzie.'
                    },
                    bottom: {
                        black: 'Przy zgłoszeniu zaproponujemy Ci trzy, opisane wcześniej sposoby likwidacji szkody.\n',
                        blue: 'Ty wybierzesz najbardziej komfortową dla Ciebie opcję.'
                    }
                }
            },
            basicInformationSection: {
                title: 'Podstawowe informacje dotyczące produktu fitcasco',
                goodToKnow: {
                    title: 'Co warto wiedzieć?',
                    collapsable1: {
                        title: 'Zapis Ogólnych Warunków Ubezpieczenia',
                        body: {
                            black1: 'Ogólne Warunki Ubezpieczenia ,,FITCASCO” (zwane dalej OWU) zawierają szczegółowe informacje na temat ubezpieczenia fitcasco, w tym:\n\n- definicje produktowe,\n- zakres odpowiedzialności dotyczący zawieranego ubezpieczenia,\n- wyłączenia generalne oraz produktowe,\n- informacje o sposobie rozwiązania umowy, w tym odstąpienia od ubezpieczenia zawartego na odległość,\n- informacje o miejscu i sposobie składania reklamacji,\n- informacje o możliwości pozasądowego rozstrzygania sporów wynikających z umowy  oraz  informacje o sądzie właściwym do rozstrzygania sporów związanych  wykonywaniem umowy.\n\n',
                            blue1: 'Niniejszy dokument jest przedkładany zgodnie z Regulaminem Serwisu wraz z ofertą ubezpieczenia.\n\n',
                            black2: 'Informacja o szczegółowych warunkach produktu dostępna jest również poprzez kontakt z Zespołem Obsługi Klienta fitcasco:\n\n'
                        }
                    },
                    collapsable2: {
                        title: 'Warunki przystąpienia do ubezpieczenia',
                        body: {
                            black1: 'Przystąpienie do ubezpieczenia jest możliwe dla wszystkich klientów Serwisu, którzy przed przystąpieniem do kalkulacji zaakceptują Regulamin Serwisu oraz którzy posiadają token lub kontaktują się z Zespołem Obsługi Klienta fitcasco, korzystając z numeru telefonu podanego w stopce strony.\n\n',
                            blue1: 'Zawarcie ubezpieczenia w tym pakiecie jest związane ze spełnieniem kilku warunków łącznie\n\n',
                            black2: 'Właściciel ubezpieczanego pojazdu spowodował maksymalnie 2 szkody komunikacyjne w okresie ostatnich 5 lat (z ubezpieczenia OC lub AC),\n\nUbezpieczany pojazd jest pomiędzy 3 a 15 rokiem eksploatacji licząc datę produkcji z dowodu rejestracyjnego, a jego wartość wynosi od 8 000 zł do 150 000 zł.\n\nW przypadku zaistnienia wątpliwości, związanych z możliwością przystąpienia do ubezpieczenia fitcasco, zapraszamy do kontaktu z Zespołem Obsługi Klienta fitcasco:\n\n'
                        }
                    },
                    collapsable3: {
                        title: 'Warianty ubezpieczenia AC',
                        body: {
                            black1: 'W ramach pakietu fitcasco, dostępne są 3 warianty ubezpieczenia AC:\n\n',
                            blue1: 'wariant 10 000 zł\n',
                            black2: 'W ramach niniejszego wariantu, zawarte ubezpieczenie obejmuje odpowiedzialność z tytułu:\n',
                            blue2: '- szkód częściowych ',
                            black3: '- do maksymalnej kwoty ',
                            blue3: '10 000 zł ',
                            black4: ' w ramach jednego okresu ubezpieczenia,\n',
                            blue4: '- szkody kradzieżowej ',
                            black5: '- do wartości pojazdu, obliczonej na dzień kradzieży pojazdu (równej początkowej sumie ubezpieczenia podanej w ubezpieczeniu, pomniejszonej o rzeczywiste użytkowanie do dnia zdarzenia objętego ubezpieczeniem),\n',
                            blue5: '- szkody całkowitej ',
                            black6: '- obliczane, jak w przypadku szkody kradzieżowej.\n\n',
                            blue6: 'wariant 5 000 zł\n',
                            black7: 'W ramach niniejszego wariantu, zawarte ubezpieczenie obejmuje odpowiedzialność z tytułu:\n',
                            blue7: '- szkód częściowych ',
                            black8: 'do maksymalnej kwoty ',
                            blue8: '5 000 zł ',
                            black9: 'w ramach jednego okresu ubezpieczenia,\n',
                            blue9: '- szkody kradzieżowej ',
                            black10: '- do wartości pojazdu, obliczonej na dzień kradzieży pojazdu (równej początkowej sumie ubezpieczenia podanej w ubezpieczeniu, pomniejszonej o rzeczywiste użytkowanie do dnia zdarzenia objętego ubezpieczeniem),\n',
                            blue10: '- szkody całkowitej',
                            black11: '- obliczane, jak w przypadku szkody kradzieżowej.\n\n',
                            blue11: 'wariant szkoda całkowita\n',
                            black12: ' W ramach niniejszego wariantu, zawarte ubezpieczenie obejmuje odpowiedzialność z tytułu:\n',
                            blue12: '- szkody kradzieżowej ',
                            black13: '- do wartości pojazdu, obliczonej na dzień kradzieży pojazdu (równej początkowej sumie ubezpieczenia podanej w ubezpieczeniu, pomniejszonej o rzeczywiste użytkowanie do dnia zdarzenia objętego ubezpieczeniem),\n',
                            blue13: '- szkody całkowitej ',
                            black14: '- obliczane, jak w przypadku szkody kradzieżowej.'
                        }
                    },
                    collapsable4: {
                        title: 'Płatność za polisę',
                        body: {
                            black1: 'Zgodnie z ',
                            blue1: 'OWU, ',
                            black2: 'ustalenie wysokości składki następuje po dokonaniu oceny ryzyka ubezpieczeniowego na podstawie taryfy w dniu zawarcia umowy ubezpieczenia.\n\n',
                            blue2: 'Składka ubezpieczeniowa płatna jest jednorazowo lub przy zastosowaniu półrocznego podziału płatności.'
                        }
                    },
                    collapsable5: {
                        title: 'Wyłączenia odpowiedzialności',
                        body: {
                            black1: 'Zapis ',
                            blue1: 'OWU ',
                            black2: ' wymienia wyłączenia odpowiedzialności w podziale na wyłączenia generalne i produktowe, do których należy zwolnienie od odpowiedzialności Zakładu Ubezpieczeń w przypadku, gdy m.in.:\n- szkoda została ',
                            blue2: 'wyrządzona umyślnie ',
                            black3: 'przez Ubezpieczonego,\n- Ubezpieczony wyrządził szkodę wskutek ',
                            blue3: 'rażącego niedbalstwa,\n',
                            black4: '- Ubezpieczony lub uprawniony kierujący, w chwili wypadku ',
                            blue4: 'znajdował się w stanie nietrzeźwości, ',
                            black5: 'w stanie po użyciu alkoholu lub pod wpływem środków odurzających, substancji psychotropowych lub środków zastępczych, w rozumieniu przepisów o przeciwdziałaniu narkomanii.\n\nDodatkowo, zawarte w ',
                            blue5: 'OWU ',
                            black6: 'wyłączenia produktowe wskazują na zwolnienie od odpowiedzialności Zakładu Ubezpieczeń od szkód powstałych m.in:\n- w pojazdach specjalnych i ',
                            blue6: 'specjalnego przeznaczenia, zarejestrowanych za granicą, ',
                            black7: 'w pojazdach wykonanych w systemie gospodarczych (tzw. samy) i poza firmą producenta (tzw. składaki),\n- w pojeździe ',
                            blue7: 'nielegalnie wprowadzonym ',
                            black8: 'na polski obszar celny,\n- w skutek ',
                            blue8: 'strajków, zamieszek, działań wojennych, stanu wojennego, rozruchów, sabotażu lub aktów terroru.\n\n',
                            black9: 'Szczegółowe informacje dot. wyłączeń odpowiedzialności Zakładu Ubezpieczeń, są dostępne również poprzez kontakt z Zespołem Obsługi Klienta fitcasco:\n\n'
                        }
                    }
                },
                mostCommonQuestions: {
                    title: 'Najczęściej zadawane pytania dotyczące produktu fitcasco',
                    collapsable1: {
                        title: 'Zapis Ogólnych Warunków Ubezpieczenia',
                        body: {
                            blue1: 'Do najważniejszych czynności wymaganych po zawarciu ubezpieczenia należą:\n\n',
                            black1: '- konieczność ',
                            blue2: 'dokonania opłaty ',
                            black2: '(dane do przelewu, w tym nr rachunku bankowego oraz kwota są wskazane na dokumencie polisy),\n',
                            blue3: '- wypowiedzenie polisy ubezpieczenia ',
                            black3: 'u poprzedniego ubezpieczyciela (jeżeli taka była) zgodnie z podstawą prawną opisaną w ',
                            blue4: 'punkcie 3.1 poniżej,\n',
                            black4: '- w przypadku wyboru ubezpieczenia AC ',
                            blue5: 'konieczność przedłożenia zdjęć pojazdu ',
                            black5: 'zgodnie z ',
                            blue6: 'Instrukcją wykonywania zdjęć ',
                            black6: 'do ubezpieczenia AC.'
                        }
                    },
                    collapsable2: {
                        title: 'W jaki sposób wykonać zdjęcia pojazdu wymagane do ubezpieczenia AC?',
                        body: {
                            blue1: 'Instrukcja wykonywania zdjęć do ubezpieczenia AC w fitcasco dostępna jest tutaj.\n\n',
                            black1: 'Powyższa instrukcja zawiera przykłady poprawnie wykonanych fotografii oraz ważne informacje w sprawie zawarcia ubezpieczenia AC dla pojazdów uszkodzonych.\nZdjęcia pojazdu po zawarciu ubezpieczenia można dostarczyć z wykorzystaniem:\n',
                            blue2: '- indywidualnego konta polisowego ',
                            black2: 'utworzonego dla klienta i jego polisy po zawarciu ubezpieczenia,\n- adresu e-mailowego ',
                            blue3: 'obsluga.fitcasco@bikserwis.pl.\n\nDopuszczalne formaty plików zdjęć:',
                            black3: '.jpg, .gif, .bmp, .png, .zip, .rar, .pdf, .doc, .rtf, .txt.\n',
                            blue4: 'Wielkość pliku',
                            black4: 'nie powinna przekraczać 10 MB.\n\n',
                            blue5: 'W przypadku ubezpieczenia AC w jednym z dostępnych wariantów fitcasco, informujemy, iż zdjęcia ubezpieczanego pojazdu stanowią część dokumentacji ubezpieczeniowej, o której uzupełnienie prosimy po zawarciu polisy.'
                        }
                    },
                    collapsable3: {
                        title: 'Czy można rozszerzyć zakres oferowanej ochrony?',
                        body: {
                            blue1: 'Ubezpieczenie w ramach pakietu fitcasco dostępne jest tylko w zakresie i na warunkach definiowanych przez OWU.\n\n',
                            black1: 'Nie ma możliwości rozszerzenia zakresu ubezpieczenia do już zawartej polisy fitcasco. Można zawrzeć dodatkową polisę ubezpieczenia zawierającą tylko wybrane ryzyka, gdyż warunki produktu fitcasco akceptują wybór każdego z zakresów osobno. Oferta ochrony poszczególnych ryzyk została określona w ',
                            blue2: 'OWU.\n\n',
                            black2: 'Jedyne ryzyko, które może być dokupione w trakcie trwania ochrony ubezpieczenia na zawartej polisie to ',
                            blue3: 'Zielona Karta ',
                            black3: '- więcej informacji dostępne w Zespole Wsparcia Klienta fitcasco pod numerem telefonu dostępnym w stopce strony.'
                        }
                    },
                    collapsable4: {
                        title: 'Co oznacza szkoda całkowita?',
                        body: {
                            blue1: 'Pojęcie szkody całkowitej jest definiowane w OWU.\n\n',
                            black1: 'Jest to ',
                            blue2: 'kradzież ',
                            black2: 'całego pojazdu lub ',
                            blue3: 'szkoda, ',
                            black3: 'dla której ',
                            blue4: 'koszty naprawy przekraczają 70% wartości rynkowej pojazdu ',
                            black4: 'w dniu powstania szkody, nie wyższej niż suma ubezpieczenia ustalona przy zawarciu ubezpieczenia w pakiecie fitcasco.'
                        }
                    },
                    collapsable5: {
                        title: 'W jaki sposób zmienia się suma ubezpieczenia AC po szkodzie?',
                        body: {
                            blue1: 'Szczegółowe informacje na temat sumy ubezpieczenia ubezpieczanego pojazdu w różnych wariantach fitcasco można odnaleźć w OWU.\n\n',
                            black1: 'W przypadku zarówno szkody częściowej jak i całkowitej, ustalona w pakiecie suma ubezpieczenia ulega ',
                            blue2: 'każdorazowemu pomniejszeniu ',
                            black2: 'o kwotę wypłaconego odszkodowania (konsumpcja sumy ubezpieczenia). W momencie całkowitego wykorzystania sumy ubezpieczenia dla wybranego wariantu fitcasco, umowa w tym zakresie ulega rozwiązaniu.\n\n',
                            blue3: 'Przykładowo: ',
                            black3: 'akupiony został wariant fitcasco 5000 zł i ubezpieczone auto uległo wypadkowi. Wybrany został wariant likwidacji szkody w sieci partnerskiej BIK, a warsztat dokonujący naprawy wskazał koszt naprawy w wysokości 3500 zł. Po dokonaniu naprawy na ubezpieczanym pojeździe ',
                            blue4: 'zostaje 1500 zł do wykorzystania, przy likwidacji szkody częściowej w danym okresie ubezpieczenia.'
                        }
                    },
                    collapsable6: {
                        title: 'Gdzie obowiązuje ochrona dla poszczególnych ubezpieczeń?',
                        body: {
                            blue1: 'Zakres terytorialny poszczególnych ubezpieczeń jest zależny od wykupionego pakietu.\n\nOC',
                            black1: '– Andora, Austria, Belgia, Bułgaria, Chorwacja, Cypr, Czechy, Dania, Estonia, Finlandia, Francja, Grecja, Hiszpania, Holandia, Irlandia, Islandia, Litwa, Łotwa, Malta, Niemcy, Norwegia, Polska, Portugalia, Rumunia, Szwajcaria, Szwecja, Słowacja, Słowenia, Wielka Brytania, Włochy, Węgry, a od 1 stycznia 2012 r. także Serbia,\n\n',
                            blue2: 'Zielona Karta ',
                            black2: '– Albania, Białoruś, Bośnia i Hercegowina, Iran, Izrael, Macedonia, Maroko, Mołdawia, Rosja Czarnogóra, Tunezja, Turcja, Ukraina,\n\n',
                            blue3: 'Autocasco ',
                            black3: '- w granicach geograficznych Europy (w tym Polska). Z zastrzeżeniem, że na terytorium Białorusi, Mołdawii, Rosji i Ukrainy wprowadza się udział własny w wysokości 1.000,00 zł,\n\n',
                            blue4: 'Następstwa Nieszczęśliwych Wypadków ',
                            black4: '- w granicach geograficznych Europy (w tym Polska),\n\n',
                            blue5: 'Assistance ',
                            black5: '– podstawowy zakres terytorialny obejmuje granice Rzeczypospolitej Polskiej. Rozszerzenie zakresu terytorialnego o granice geograficzne Europy zależne jest od wybranego pakietu ubezpieczeń.'
                        }
                    },
                    collapsable7: {
                        title: 'Jaki jest zakres holowania w ubezpieczeniu Assistance?',
                        body: {
                            blue1: 'Szczegółowe informacje na temat ubezpieczenia assistance można odnaleźć w OWU.\n\nHolowanie pojazdu ',
                            black1: '(gdy naprawienie nie jest możliwe w miejscu wystąpienia zdarzenia) do miejsca wskazanego przez Ubezpieczonego na ',
                            blue2: 'odległość nie większą niż 500 km na terenie Polski. ',
                            black2: 'Zakres holowania, w przypadku wystąpienia wypadku ubezpieczeniowego poza granicami Polski, jest zależny od wybranego pakietu ubezpieczeń. Dodatkowo, zgłoszenie natychmiastowej pomocy drogowej jest możliwe już ',
                            blue3: 'z miejsca zamieszkania ',
                            black3: '- nie ma limitu dolnego odpowiedzialności (tzw. franszyza kilometrowa).'
                        }
                    },
                    collapsable8: {
                        title: 'Jak mogę wprowadzić zmianę adresu zamieszkania lub numeru rejestracyjnego?',
                        body: {
                            blue1: 'Zmiany danych na polisie ubezpieczenia można dokonać za pośrednictwem kontaktu z Zespołem Obsługi Klienta fitcasco lub drogą mailową.\n\n',
                            black1: 'W celu wprowadzenia zmiany ',
                            blue2: 'danych pojazdu ',
                            black2: 'prosimy o przedłożenie skanu dowodu rejestracyjnego. Jeżeli nastąpiła ',
                            blue3: 'zmiana numeru rejestracyjnego ',
                            black3: 'i Klient jest w posiadaniu tymczasowego dowodu rejestracyjnego również należy go przedłożyć.\n\n'
                        }
                    },
                    collapsable9: {
                        title: 'Jaka jest definicja urządzeń zabezpieczających pojazd przed kradzieżą?',
                        body: {
                            blue1: 'Definicja urządzenia zabezpieczającego przed kradzieżą jest wskazana w OWU fitcasco.\n\n',
                            black1: 'Urządzenie zabezpieczające pojazd przed kradzieżą spełnia warunki wynikające z OWU, m.in.:\n- jest to niezależny, samodzielny, mechaniczny lub elektroniczny ',
                            blue2: 'system zabezpieczenia przeciwkradzieżowego,\n',
                            black2: '- posiada on ',
                            blue3: 'określoną klasę skuteczności,\n',
                            black3: '- jest ',
                            blue4: ' trwale zamontowany ',
                            black4: 'przez stację obsługi posiadającą odpowiednią licencję do montażu od producenta danego zabezpieczenia lub serwisowania zabezpieczeń antykradzieżowych,\n- jest ',
                            blue5: 'sprawny w dniu montażu ',
                            black5: 'lub ',
                            blue6: 'stanowi fabryczne wyposażenie pojazdu.\n\nUrządzeniem zgodnym z definicją nie jest mechaniczna i elektromechaniczna blokada kierownicy, zamki w drzwiach pojazdu i stacyjka.\n\n',
                            black6: 'Więcej szczegółowych informacji dostępnych jest w definicji dostępnej w  ',
                            blue7: 'OWU fitcasco.'
                        }
                    }
                },
                otherInformations: {
                    title: 'Inne ważne kwestie dotyczące ubezpieczeń komunikacyjnych',
                    collapsable1: {
                        title: 'Wypowiedzenie ubezpieczenia OC',
                        body: {
                            blue1: 'Zgodnie z przepisami prawa, aby złożone wypowiedzenie było skuteczne, konieczne jest zachowanie formy pisemnej  składanego oświadczenia.\n\n',
                            black1: ' Wypowiedzenie ubezpieczenia obowiązkowego OC jest prawnie wiążącym oświadczeniem woli, możliwym do złożenia w 3 formach prawnych, definiowanych ustawą z dnia 22 maja 2003 r. o ubezpieczeniach obowiązkowych, Ubezpieczeniowym Funduszu Gwarancyjnym i Polskim Biurze Ubezpieczycieli Komunikacyjnych - zwanej dalej Ustawą OC.\n\n1) Wypowiedzenie ubezpieczenia ',
                            blue2: 'w związku z końcem ochrony na dotychczasowej polisie (art. 28 ust.1 Ustawy OC) ',
                            black2: '- wypowiedzenie z tej podstawy prawnej należy: wysłać do dotychczasowego towarzystwa ubezpieczeń najpóźniej na jeden dzień przed końcem dotychczasowej polisy. Dla potrzeb weryfikacji skuteczności wypowiedzenia bierze się pod uwagę ',
                            blue3: ' datę nadania ',
                            black3: 'dokumentu (list polecony, adres e-mail),\n2) Wypowiedzenie ubezpieczenia',
                            blue4: 'w związku z posiadaniem podwójnego ubezpieczenia OC (art. 28a ust. 1 Ustawy OC) ',
                            black4: '- ten rodzaj wypowiedzenia jest stosowany w przypadku jednoczesnego posiadania wznowionego automatycznie ubezpieczenia u dotychczasowego towarzystwa ubezpieczeń oraz nowo zawartej polisy OC.\nRozliczenie dotychczasowego ubezpieczenia następuje z końcem daty, z którą zostało nadane pismo z wypowiedzeniem, a dotychczasowy ubezpieczyciel ma prawo\nnaliczyć opłatę za każdy dzień trwania polisy do momentu nadania pisma z wypowiedzeniem.\n3) Wypowiedzenie ubezpieczenia ',
                            blue5: 'w związku z zakupem pojazdu objętego ubezpieczeniem OC, zawartym przez poprzedniego właściciela (art. 31 ust. 1 Ustawy OC) ',
                            black5: 'ten rodzaj wypowiedzenia jest stosowany w przypadku woli rozwiązania polisy OC przepisywanej na nabywcę pojazdu po poprzednim właścicielu. Rozliczenie dotychczasowego ubezpieczenia następuje z końcem daty, z którą zostało nadane pismo z wypowiedzeniem, a dotychczasowy ubezpieczyciel ma prawo naliczyć opłatę za każdy dzień trwania polisy do momentu nadania pisma z wypowiedzeniem, która jest pomniejszona o składkę opłaconą lecz niewykorzystaną przez zbywcę pojazdu.\n\n',
                            blue6: 'Wzór dokumentu wypowiedzenia do pobrania'
                        }
                    },
                    collapsable2: {
                        title: 'Formy odstąpienia od zawartego ubezpieczenia',
                        body: {
                            blue1: 'Klientom, będącym konsumentami w rozumieniu ustawy z dnia 30 maja 2014 r. o prawach konsumenta, przysługuje prawo do odstąpienia od umowy zawartej na odległość w terminie 30 dni.\n\nPowyższy termin ',
                            black1: 'stosowany jest:\n- od dnia poinformowania go o zawarciu umowy lub\n- od dnia potwierdzenia informacji, o których mowa w art 39 ust. 1 w/w ustawy, o ile jest to termin późniejszy.\n\nDla potrzeb weryfikacji skuteczności nadesłanego oświadczenia, ',
                            blue2: 'przyjmuje się datę jego nadania.\n\nOdstąpienie od umowy nie zwalnia Ubezpieczającego z obowiązku zapłaty składki za okres, w którym zakład ubezpieczeń udziela ochrony ubezpieczeniowej.\n\n',
                            black2: 'Dostępna jest również forma odstąpienia od zawartych na ubezpieczeniu ryzyk dobrowolnych (m.in. AC, Assistance, NNW), zgodnie z zasadami z ',
                            blue3: 'OWU.\n',
                            black3: 'Zgodnie z ',
                            blue4: 'OWU ',
                            black4: 'fitcasco, ',
                            blue5: 'Ubezpieczający może złożyć odstąpienie:\n',
                            black5: '1) poprzez ',
                            blue6: 'formularz na stronie internetowej Zakładu Ubezpieczeń\n',
                            black6: '2) ',
                            blue7: 'telefonicznie ',
                            black7: '- poprzez infolinię Zakładu Ubezpieczeń\n3) ',
                            blue8: 'pisemnie ',
                            black8: '- na adres siedziby Zakładu Ubezpieczeń\n\n',
                            blue9: 'Wzór dokumentu odstąpienia do pobrania'
                        }
                    },
                    collapsable3: {
                        title: 'Zmiana właściciela / sprzedaż / demontaż pojazdu',
                        body: {
                            blue1: 'Zbywca pojazdu zgodnie z treścią Ustawy OC (patrz pkt 3.1), ma obowiązek poinformować zakład ubezpieczeń o zmianie właściciela w terminie 14 dni.\n\n',
                            black1: 'Informacja o takiej zmianie powinna zawierać dane nowego właściciela pojazdu, w tym ',
                            blue2: 'w szczególności:\n\n- dane osobowe',
                            black2: 'lub ',
                            blue3: 'identyfikacja podmiotu ',
                            black3: 'będącego nabywcą pojazdu:\na) dla ',
                            blue4: 'osoby fizycznej ',
                            black4: '- imię, nazwisko, PESEL, adres zamieszkania z kodem pocztowym,\nb) dla ',
                            blue5: 'osoby prawnej ',
                            black5: '- nazwa podmiotu, NIP, REGON, adres siedziby.\n',
                            blue6: '- dane pojazdu ',
                            black6: 'będącego przedmiotem umowy/aktu zmieniającego własność,\n',
                            blue7: '- datę, ',
                            black7: 'z którą nastąpiło przekazanie własności.\n\n',
                            blue8: 'Najczęściej stosowanym dokumentem ',
                            black8: 'otwierdzającym wskazaną zmianę jest ',
                            blue9: 'umowa sprzedaży ',
                            black9: 'lub',
                            blue10: 'faktura VAT.\nTaki dokument należy przesłać bezpośrednio do zakładu ubezpieczeń.\n\n Demontaż pojazdu zgodnie z zapisami ustawy OC (patrz pkt 3.1) oraz OWU fitcasco jest podstawą do wygaśnięcia umowy ubezpieczenia.\n\n Najczęściej stosowanym dokumentem ',
                            black10: 'potwierdzającym złomowanie pojazdu jest ',
                            blue11: 'zaświadczenie o demontażu pojazdu ',
                            black11: 'z punktu złomowania lub ',
                            blue12: 'decyzja o wyrejestrowaniu pojazdu, ',
                            black12: 'wydana przez odpowiedni Urząd, z określoną w treści dokumentu faktyczną datą demontażu.\n',
                            blue13: 'Taki dokument należy przesłać bezpośrednio do zakładu ubezpieczeń.'
                        }
                    }
                }
            },
            footerSection: {
                contactDetails: 'Multiagencja Ubezpieczeniowa BIK Serwis\nSiedziba spółki:\nul. Polanki 136/8\n80-322 Gdańsk.\n+48 587 706 200\ninfo@bikserwis.pl.',
                offer: 'Oferta przygotowane we współpracy z:',
                terms: 'Regulamin i Polityka prywatności',
                contactBox: {
                    title: 'Skontaktuj się z nami,\njeśli masz pytania\nlub wątpliwości.',
                    phone: '+48 587 706 215',
                    openHours: 'pon-pt 09:00-17:00'
                }
            }
        }
    }
}
