import styled from 'styled-components'
import { colors } from 'lib/styles'

export const Text = styled.div`
    font-size: 14px;
    color: ${colors.gray};
    white-space: pre-line;
    font-weight: 400;
`

export const BlueSpan = styled.span`
    color: ${colors.mediumBlue};
    font-weight: bold;
`

export const Link = styled.a`
    text-decoration: none;
    font-weight: bold;
`
