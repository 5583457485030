import { useCallback, useEffect, useRef, useState } from 'react'

export const useResize = () => {
    const ref = useRef<HTMLDivElement>(null)
    const [ width, setWidth ] = useState<number>(typeof window !== 'undefined' ? window.innerWidth : 0)
    const [ height, setHeight ] = useState<number>(typeof window !== 'undefined' ? window.innerHeight : 0)
    const [ itemWidth, setItemWidth ] = useState<number>(0)
    const [ itemHeight, setItemHeight ] = useState<number>(0)
    const onResize = useCallback(() => {
        const item = ref.current
        setWidth(window.innerWidth)
        setHeight(window.innerHeight)
        if (item) {
            setItemWidth(item.offsetWidth)
            setItemHeight(item.offsetHeight)
        }
    }, [])

    useEffect(() => {
        onResize()
        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return {
        window: {
            width,
            height
        },
        item: {
            height: itemHeight,
            width: itemWidth
        },
        refresh: onResize,
        ref
    }
}
