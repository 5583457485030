import React from 'react'
import styled from 'styled-components'
import { images } from 'assets'
import { Text, BlueSpan } from './Text'

export const ContactInfo: React.FunctionComponent = () => (
    <Container>
        <ContainerRow>
            <Icon src={images.bluePhone}/>
            <Text>
                <BlueSpan>
                    +48 587 706 215
                </BlueSpan>
            </Text>
        </ContainerRow>
        <ContainerRow>
            <Icon src={images.blueEmail}/>
            <Text>
                <BlueSpan>
                    obsluga.fitcasco@bikserwis.pl
                </BlueSpan>
            </Text>
        </ContainerRow>
    </Container>
)

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const ContainerRow = styled.div`
    display: flex;
    margin-bottom: 15px;
`

const Icon = styled.img`
    margin-right: 5px;
`
