import React from 'react'
import { useTranslations } from 'lib/hooks'
import { Text, BlueSpan } from './Text'

export const GoodToKnowCollapsable4: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.basicInformationSection.goodToKnow

    return (
        <Text>
            {T.collapsable4.body.black1}
            <BlueSpan>
                {T.collapsable4.body.blue1}
            </BlueSpan>
            {T.collapsable4.body.black2}
            <BlueSpan>
                {T.collapsable4.body.blue2}
            </BlueSpan>
        </Text>
    )
}
