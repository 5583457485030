import React from 'react'
import { useTranslations } from 'lib/hooks'
import { Text, BlueSpan } from './Text'
import { ContactInfo } from './ContactInfo'

export const GoodToKnowCollapsable2: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.basicInformationSection.goodToKnow

    return (
        <Text>
            {T.collapsable2.body.black1}
            <BlueSpan>
                {T.collapsable2.body.blue1}
            </BlueSpan>
            {T.collapsable2.body.black2}
            <ContactInfo/>
        </Text>
    )
}
