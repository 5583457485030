import React from 'react'
import styled from 'styled-components'
import { Typography, Collapsable } from 'lib/components'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import {
    MostCommonCollapsable1,
    MostCommonCollapsable2,
    MostCommonCollapsable3,
    MostCommonCollapsable4,
    MostCommonCollapsable5,
    MostCommonCollapsable6,
    MostCommonCollapsable7,
    MostCommonCollapsable8,
    MostCommonCollapsable9
} from './collapsables'

export const MostCommonQuestions: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.basicInformationSection

    return (
        <Container>
            <Subtitle>
                {T.mostCommonQuestions.title}
            </Subtitle>
            <Collapsable title={T.mostCommonQuestions.collapsable1.title}>
                <MostCommonCollapsable1/>
            </Collapsable>
            <Collapsable title={T.mostCommonQuestions.collapsable2.title}>
                <MostCommonCollapsable2/>
            </Collapsable>
            <Collapsable title={T.mostCommonQuestions.collapsable3.title}>
                <MostCommonCollapsable3/>
            </Collapsable>
            <Collapsable title={T.mostCommonQuestions.collapsable4.title}>
                <MostCommonCollapsable4/>
            </Collapsable>
            <Collapsable title={T.mostCommonQuestions.collapsable5.title}>
                <MostCommonCollapsable5/>
            </Collapsable>
            <Collapsable title={T.mostCommonQuestions.collapsable6.title}>
                <MostCommonCollapsable6/>
            </Collapsable>
            <Collapsable title={T.mostCommonQuestions.collapsable7.title}>
                <MostCommonCollapsable7/>
            </Collapsable>
            <Collapsable title={T.mostCommonQuestions.collapsable8.title}>
                <MostCommonCollapsable8/>
            </Collapsable>
            <Collapsable title={T.mostCommonQuestions.collapsable9.title}>
                <MostCommonCollapsable9/>
            </Collapsable>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    margin-top: 100px;
`

const Subtitle = styled(Typography.Paragraph)`
    font-size: 16px;
    color: ${colors.mediumBlue};
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bold;
`
