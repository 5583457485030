import React from 'react'
import { useTranslations } from 'lib/hooks'
import { Text, BlueSpan } from './Text'

export const MostCommonCollapsable7: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.basicInformationSection.mostCommonQuestions

    return (
        <Text>
            <BlueSpan>
                {T.collapsable7.body.blue1}
            </BlueSpan>
            {T.collapsable7.body.black1}
            <BlueSpan>
                {T.collapsable7.body.blue2}
            </BlueSpan>
            {T.collapsable7.body.black2}
            <BlueSpan>
                {T.collapsable7.body.blue3}
            </BlueSpan>
            {T.collapsable7.body.black3}
        </Text>
    )
}
