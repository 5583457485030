import React from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'

type ButtonType = {
    type: 'filled' | 'outline'
}

interface ButtonProps extends ButtonType {
    onPress: VoidFunction,
    text: string
}

export const Button: React.FunctionComponent<ButtonProps> = ({
    text,
    onPress,
    type
}) => (
    <Container
        type={type}
        onClick={() => onPress()}
    >
        {text}
    </Container>
)

const Container = styled.div<ButtonType>`
    width: 100%;
    height: 60px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: ${props => props.type === 'filled' ? colors.white : colors.green};
    background-color: ${props => props.type === 'filled' ? colors.green : colors.transparent};
    border: solid 2px ${colors.green};
    cursor: pointer;
    text-transform: uppercase;
`
