import React from 'react'
import styled from 'styled-components'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { Tabs } from '../types'

type InsuranceTabsHeaderProps = {
    activeTab: Tabs,
    onTabPress(tab: Tabs): void
}

type IsTabActive = {
    isTabActive: boolean
}

export const InsuranceTabsHeader: React.FunctionComponent<InsuranceTabsHeaderProps> = ({
    activeTab,
    onTabPress
}) => {
    const T = useTranslations()

    return (
        <Container>
            <HeaderTab
                isTabActive={activeTab === Tabs.First}
                onClick={() => onTabPress(Tabs.First)}
            >
                {T.screens.homeScreen.insuranceSection.tabs.leftTabTitle}
                <BoldSpan>
                    {T.screens.homeScreen.insuranceSection.tabs.leftTabBoldTitle}
                </BoldSpan>
            </HeaderTab>
            <HeaderTab
                isTabActive={activeTab === Tabs.Second}
                onClick={() => onTabPress(Tabs.Second)}
            >
                {T.screens.homeScreen.insuranceSection.tabs.rightTabTitle}
                <BoldSpan>
                    {T.screens.homeScreen.insuranceSection.tabs.rightTabBoldTitle}
                </BoldSpan>
            </HeaderTab>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    display: flex;
    height: 100px;
    background-color: ${colors.tabsBlue};
`

const HeaderTab = styled.div<IsTabActive>`
    flex: 1;
    height: 100px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
    background-color: ${props => props.isTabActive ? colors.white : colors.transparent};
    font-size: 14px;
    color: ${colors.mediumBlue};
    font-weight: 400;
    white-space: pre-line;
`

const BoldSpan = styled.span`
    font-weight: bold;
    text-transform: uppercase;
`
