import React, { useState } from 'react'
import styled from 'styled-components'
import { images } from 'assets'
import { colors } from '../styles'
import { useResize } from '../hooks'

type IsVisible = {
    isVisible: boolean,
    additionalHeight?: number
}

type CollapsableProps = {
    title: string
}

export const Collapsable: React.FunctionComponent<CollapsableProps> = ({ children, title }) => {
    const [isVisible, setIsVisible] = useState(false)
    const { ref, item: { height } } = useResize()

    return (
        <Container
            isVisible={isVisible}
            additionalHeight={height}
        >
            <ContainerHeader>
                <Title isVisible={isVisible}>
                    {title}
                </Title>
                <ButtonWrapper onClick={() => setIsVisible(prevState => !prevState)}>
                    <Icon src={isVisible ? images.minusIcon : images.plusIcon}/>
                </ButtonWrapper>
            </ContainerHeader>
            <ContentContainer>
                <ContentWrapper ref={ref}>
                    {children}
                </ContentWrapper>
            </ContentContainer>
        </Container>
    )
}

const Container = styled.div<IsVisible>`
    height: ${props => props.isVisible ? 70 + (props?.additionalHeight || 0) + 80 : 70}px;
    overflow: hidden;
    width: 100%;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    border-radius: 6px;
    box-shadow: ${colors.boxShadow};
    transition: height 0.25s linear;
`

const ContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    user-select: none;
`

const Title = styled.div<IsVisible>`
    font-size: 16px;
    font-weight: 400;
    color: ${props => props.isVisible ? colors.mediumBlue : colors.gray}
`

const ButtonWrapper = styled.div`
    width: 40px;
    height: 40px;
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const Icon = styled.img`
    object-fit: contain;
`

const ContentContainer = styled.div`
    position: relative;
    width: 100%;
`

const ContentWrapper = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
    position: absolute;
    left: 20px;
    right: 20px;
    top: 0;
`
