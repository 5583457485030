import styled from 'styled-components'
import { MediaBreakpoints } from 'lib/styles'

type FluidContainerProps = {
    maxWidth?: number
}

export const FluidContainer = styled.div<FluidContainerProps>`
    max-width: ${props => props.maxWidth || 1360}px;
    margin: 0 auto;
    padding: 0 40px;
    @media (max-width: ${MediaBreakpoints.SM}px) {
        padding: 0 25px;
    }
`
