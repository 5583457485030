export const colors = {
    black: '#000000',
    white: '#FFFFFF',
    blue: '#024079',
    lightBlue: '#F7F9FE',
    mediumBlue: '#3766A7',
    gray: '#818181',
    green: '#70A006',
    tabsBlue: '#E8EFFF',
    red: '#C70000',
    transparent: 'transparent',
    boxShadow: `0px 0px 20px 0px rgba(0,0,0,0.17)`
}
