import React from 'react'
import styled from 'styled-components'
import { images } from 'assets'
import { colors } from 'lib/styles'

type IsSelected = {
    isSelected: boolean
}

interface CheckboxProps extends IsSelected {
    onChange(isSelected: boolean): void,
    rightComponent(): React.ReactNode
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
    rightComponent,
    isSelected,
    onChange
}) => (
    <Container>
        <CheckboxContainer
            onClick={() => onChange(!isSelected)}
            isSelected={isSelected}
        >
            {isSelected && (
                <CheckMark src={images.checkmarkIcon}/>
            )}
        </CheckboxContainer>
        {rightComponent()}
    </Container>
)

const Container = styled.div`
    width: 100%;
    display: flex;
`

const CheckboxContainer = styled.div<IsSelected>`
    width: 22px;
    height: 22px;
    border-radius: 4px;
    cursor: pointer;
    background-color: ${props => props.isSelected ? colors.blue : colors.white};
    border: ${props => !props.isSelected ? `solid 1px ${colors.blue}` : 'none'};
    text-align: center;
    user-select: none;
    margin-right: 20px
`

const CheckMark = styled.img``
