import React from 'react'
import styled from 'styled-components'
import { images } from 'assets'
import { Typography } from 'lib/components'
import { colors } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { AppLinks } from 'lib/common'

export const ModalInformation: React.FunctionComponent = () => {
    const T = useTranslations()

    return (
        <Container>
            <Paragraph>
                {T.components.modal.checkbox.firstParagraph}
            </Paragraph>
            <FileContainer>
                <FileIcon src={images.fileIcon}/>
                <Paragraph>
                    {T.components.modal.checkbox.secondParagraph.gray}
                    <Link href={AppLinks.Klauzula}>
                        {T.components.modal.checkbox.secondParagraph.blue}
                    </Link>
                </Paragraph>
            </FileContainer>
            <Paragraph>
                {T.components.modal.checkbox.thirdParagraph}
            </Paragraph>
        </Container>
    )
}

const Container  = styled.div`
    margin-left: 42px;
    margin-bottom: 20px
`

const Paragraph = styled(Typography.Paragraph)`
    color: ${colors.gray};
    font-weight: 400;
    font-size: 14px;
    white-space: pre-line;
    margin-bottom: 20px
`

const FileContainer = styled.div`
    display: flex;
`

const FileIcon = styled.img`
    margin-right: 10px;
    width: 15px;
    height: 21px;
    object-fit: contain;
`

const Link = styled.a`
    color: ${colors.mediumBlue};
    text-decoration: none;
`
