import React from 'react'
import { useTranslations } from 'lib/hooks'
import { Text, BlueSpan } from './Text'

export const MostCommonCollapsable9: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.basicInformationSection.mostCommonQuestions

    return (
        <Text>
            <BlueSpan>
                {T.collapsable9.body.blue1}
            </BlueSpan>
            {T.collapsable9.body.black1}
            <BlueSpan>
                {T.collapsable9.body.blue2}
            </BlueSpan>
            {T.collapsable9.body.black2}
            <BlueSpan>
                {T.collapsable9.body.blue3}
            </BlueSpan>
            {T.collapsable9.body.black3}
            <BlueSpan>
                {T.collapsable9.body.blue4}
            </BlueSpan>
            {T.collapsable9.body.black4}
            <BlueSpan>
                {T.collapsable9.body.blue5}
            </BlueSpan>
            {T.collapsable9.body.black5}
            <BlueSpan>
                {T.collapsable9.body.blue6}
            </BlueSpan>
            {T.collapsable9.body.black6}
            <BlueSpan>
                {T.collapsable9.body.blue7}
            </BlueSpan>
        </Text>
    )
}
