import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { images } from 'assets'
import { colors, MediaBreakpoints } from 'lib/styles'
import { platformUtils } from 'lib/utils'
import { Section, FluidContainer, Typography } from 'lib/components'
import { useTranslations, useWindowResize } from 'lib/hooks'
import { LongInfoText } from '../components'

type ImageMargins = {
    marginTop?: boolean,
    marginBottom?: boolean
}

export const InfoSection: React.FunctionComponent = () => {
    const T = useTranslations().screens.homeScreen.infoSection
    const { width } = useWindowResize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setIsMobile(platformUtils.isPhoneResolution(width))
    }, [width])

    return (
        <Container>
            <FluidContainer>
                <ContainerRow>
                    <ContainerColumnLeft>
                        <Header>
                            {T.leftColumn.title}
                        </Header>
                        {T.leftColumn.information.map((infoText, index) => (
                            <InfoCloudWrapper key={index}>
                                <InfoCloudImage src={images.checkMark}/>
                                <InfoCloud>
                                    <InfoCloudText>
                                        {infoText}
                                    </InfoCloudText>
                                </InfoCloud>
                            </InfoCloudWrapper>
                        ))}
                    </ContainerColumnLeft>
                    <ContainerColumnRight>
                        <AccountingImageWrapper>
                            <ColumnImage
                                marginBottom
                                src={images.accounting}
                            />
                        </AccountingImageWrapper>
                    </ContainerColumnRight>
                </ContainerRow>
                <ContainerRow>
                    <ContainerColumnLeft>
                        <CarImageDesktop
                            marginTop
                            src={images.car}
                        />
                    </ContainerColumnLeft>
                    <ContainerColumnRight>
                        <CarImageMobile
                            marginTop
                            src={images.car}
                        />
                        <Header>
                            {T.rightColumn.title}
                        </Header>
                        <LongInfoText/>
                    </ContainerColumnRight>
                </ContainerRow>
            </FluidContainer>
        </Container>
    )
}

const Container = styled(Section)`
    padding-top: 80px;
    padding-bottom: 80px;
`

const ContainerRow = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: ${MediaBreakpoints.LG}px) {
        flex-direction: column-reverse;
    }
`

const ContainerColumnLeft = styled.div`
    flex: 1;
    padding-right: 25px;
    @media (max-width: ${MediaBreakpoints.LG}px) {
        padding-right: 0px;
    }
`

const ContainerColumnRight = styled.div`
    flex: 1;
    padding-left: 25px;
    @media (max-width: ${MediaBreakpoints.LG}px) {
        padding-left: 0px;
    }
`

const InfoCloudWrapper = styled.div`
    display: flex;
`

const InfoCloudImage = styled.img`
    width: 35px;
    height: 35px;
    margin-right: 20px;
`

const InfoCloud = styled.div`
    width: 100%;
    min-height: 80px;
    border-radius: 6px;
    box-shadow: ${colors.boxShadow};
    background-color: ${colors.lightBlue};
    padding: 20px;
    margin-bottom: 20px
`

const InfoCloudText = styled(Typography.Paragraph)`
    font-weight: 400;
`

const Header = styled(Typography.H3)`
    margin-bottom: 30px;
`

const ColumnImage = styled.img<ImageMargins>`
    max-width: 550px;
    width: 100%;
    object-fit: contain;
    margin-top: ${props => props.marginTop ? `100px` : '0px'};
    margin-bottom: ${props => props.marginBottom ? `100px` : '0px'};
    @media (max-width: ${MediaBreakpoints.LG}px) {
        max-width: 400px;
        margin-top: 25px;
        margin-bottom: 25px;
    }
`

const CarImageDesktop = styled(ColumnImage)`
    @media (max-width: ${MediaBreakpoints.LG}px) {
        display: none;
    }
`

const CarImageMobile = styled(ColumnImage)`
    display: none;
    @media (max-width: ${MediaBreakpoints.LG}px) {
        display: block;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 25px;
    }
`

const AccountingImageWrapper = styled.div`
    display: block;
    text-align: right;
    @media (max-width: ${MediaBreakpoints.LG}px) {
        text-align: center;
    }
`
