import React, { useState } from 'react'
import styled from 'styled-components'
import { images } from 'assets'
import { colors, MediaBreakpoints } from 'lib/styles'
import { useTranslations } from 'lib/hooks'
import { Typography } from 'lib/components'
import { InsuranceTabsHeader } from './InsuranceTabsHeader'
import { InsuranceElement } from './InsuranceElement'
import { Tabs } from '../types'
import { HoverContent } from './HoverContent'

type InsuranceTabsProps = {
    toggleHomeModal: VoidFunction
}

export const InsuranceTabs: React.FunctionComponent<InsuranceTabsProps> = ({ toggleHomeModal }) => {
    const T = useTranslations()
    const [activeTab, setActiveTab] = useState(Tabs.First)

    return (
        <Container>
            <InsuranceTabsHeader
                activeTab={activeTab}
                onTabPress={tab => setActiveTab(tab)}
            />
            <InsuranceBody>
                <InsuranceElement
                    activeTab={activeTab}
                    title={T.screens.homeScreen.insuranceSection.tabs.semiFit.title}
                    icon={images.semiFitPromotion}
                    amount={785}
                    newAmount={1430}
                    onContactButtonClick={() => toggleHomeModal()}
                    renderContent={() => (
                        <Paragraph>
                            {T.screens.homeScreen.insuranceSection.tabs.semiFit.body.black1}
                            <BlueSpan>
                                {T.screens.homeScreen.insuranceSection.tabs.semiFit.body.blue1}
                            </BlueSpan>
                            {T.screens.homeScreen.insuranceSection.tabs.semiFit.body.black2}
                            <BlueSpan>
                                {T.screens.homeScreen.insuranceSection.tabs.semiFit.body.blue2}
                            </BlueSpan>
                        </Paragraph>
                    )}
                    renderHoverContent={() => (
                        <HoverContent
                            title={T.screens.homeScreen.insuranceSection.tabs.semiFit.hover.title}
                            paragraph={T.screens.homeScreen.insuranceSection.tabs.semiFit.hover.paragraph}
                            advantages={T.screens.homeScreen.insuranceSection.tabs.semiFit.hover.adventages}
                        />
                    )}
                />
                <InsuranceElement
                    activeTab={activeTab}
                    title={T.screens.homeScreen.insuranceSection.tabs.basicFit.title}
                    isMainPromotion
                    icon={images.basicFitPromotion}
                    amount={500}
                    newAmount={500}
                    onContactButtonClick={() => toggleHomeModal()}
                    renderContent={() => (
                        <Paragraph>
                            <RedSpan>
                                {T.screens.homeScreen.insuranceSection.tabs.basicFit.body.red1}
                            </RedSpan>
                            {T.screens.homeScreen.insuranceSection.tabs.basicFit.body.black1}
                            <RedSpan>
                                {T.screens.homeScreen.insuranceSection.tabs.basicFit.body.red2}
                            </RedSpan>
                        </Paragraph>
                    )}
                    renderHoverContent={() => (
                        <HoverContent
                            title={T.screens.homeScreen.insuranceSection.tabs.basicFit.hover.title}
                            paragraph={T.screens.homeScreen.insuranceSection.tabs.basicFit.hover.paragraph}
                            advantages={T.screens.homeScreen.insuranceSection.tabs.basicFit.hover.adventages}
                        />
                    )}
                />
                <InsuranceElement
                    activeTab={activeTab}
                    title={T.screens.homeScreen.insuranceSection.tabs.fullFit.title}
                    icon={images.fullFitPromotion}
                    amount={950}
                    newAmount={1600}
                    onContactButtonClick={() => toggleHomeModal()}
                    renderContent={() => (
                        <Paragraph>
                            {T.screens.homeScreen.insuranceSection.tabs.fullFit.body.black1}
                            <BlueSpan>
                                {T.screens.homeScreen.insuranceSection.tabs.fullFit.body.blue1}
                            </BlueSpan>
                            {T.screens.homeScreen.insuranceSection.tabs.fullFit.body.black2}
                            <BlueSpan>
                                {T.screens.homeScreen.insuranceSection.tabs.fullFit.body.blue2}
                            </BlueSpan>
                        </Paragraph>
                    )}
                    renderHoverContent={() => (
                        <HoverContent
                            title={T.screens.homeScreen.insuranceSection.tabs.fullFit.hover.title}
                            paragraph={T.screens.homeScreen.insuranceSection.tabs.fullFit.hover.paragraph}
                            advantages={T.screens.homeScreen.insuranceSection.tabs.fullFit.hover.adventages}
                        />
                    )}
                />
            </InsuranceBody>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    background-color: ${colors.white};
    box-shadow: ${colors.boxShadow};
    border-radius: 6px;
    margin-top: 50px;
    margin-bottom: 50px;
`

const InsuranceBody = styled.div`
    width: 100%;
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
    @media (max-width: ${MediaBreakpoints.MD}px) {
        flex-direction: column;
    }
`

const Paragraph = styled(Typography.Paragraph)`
    font-size: 14px;
    font-weight: 400;
    white-space: pre-line;
    margin-bottom: 20px;
    min-height: 200px;
    @media (max-width: ${MediaBreakpoints.MD}px) {
        min-height: auto;
    }
`

const BlueSpan = styled.span`
    color: ${colors.mediumBlue};
    font-weight: bold;
`

const RedSpan = styled.span`
    color: ${colors.red};
    text-transform: uppercase;
    font-weight: bold;
`
