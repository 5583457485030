import React from 'react'
import styled from 'styled-components'
import { images } from 'assets'
import { Typography } from 'lib/components'
import { colors } from 'lib/styles'

type HoverContentProps = {
    title: string,
    paragraph: string,
    advantages: Array<string>
}

export const HoverContent: React.FunctionComponent<HoverContentProps> = ({
    title,
    advantages,
    paragraph
}) => (
    <Container>
        <Title>
            {title}
        </Title>
        <Subtitle>
            {paragraph}
        </Subtitle>
        {advantages.map((advantage, index) => (
            <AdvantageRow key={index}>
                <AdvantageCheckmark src={images.checkMark}/>
                <Subtitle>
                    {advantage}
                </Subtitle>
            </AdvantageRow>
        ))}
    </Container>
)

const Container = styled.div`
    width: 100%;
    padding: 20px;
`

const Title = styled(Typography.Paragraph)`
    font-size: 16px;
    font-weight: bold;
    color: ${colors.white};
    margin-bottom: 15px;
`

const Subtitle = styled(Typography.Paragraph)`
    font-size: 14px;
    font-weight: bold;
    color: ${colors.white};
    margin-bottom: 10px;
`

const AdvantageRow = styled.div`
    display: flex;
    margin-bottom: 20px
`

const AdvantageCheckmark = styled.img`
    margin-right: 5px;
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-top: 4px;
`
